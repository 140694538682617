














































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import vuedraggable from "vuedraggable";
import { validImgUpload, createUniqueString } from "@/utils/tools";
import { IMediaModel } from "@/data/commonModel";
// import { Upload as ElUpload } from 'element-ui'
import { UserModule } from "@/store/modules/user";

// 装饰器模式
@Component({
  components: {
    vuedraggable,
  },
})
export default class extends Vue {
  // Props
  // 图片数据(图片url组成的数组) 通过v-model传递
  @Prop({ required: true })
  public value!: IMediaModel[];
  // 限制上传的图片数量
  @Prop({ default: 9 })
  limit!: number;
  // 限制上传图片的文件大小(kb)
  @Prop({ default: 2048 })
  size!: number;
  // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
  @Prop({ default: false })
  isSingle!: boolean;
  // 上传文件类型 值可为 image、video
  @Prop({ default: "image" })
  mediaType!: string;
  // 可选择的文件类型
  @Prop({ default: "" })
  acceptMediaType!: string;
  // 提示文字
  @Prop({ default: "" })
  placeholder!: string;
  // 是否使用图片压缩
  @Prop({ default: false })
  useCompress!: boolean;
  // 图片显示的宽度
  @Prop({ default: "100px" })
  width!: string;
  // 图片显示的宽度
  @Prop({ default: "100px" })
  height!: string;
  // 是否显示数组下标
  @Prop({ default: false })
  showIndex!: boolean;

  // Data
  uploadingText = "正在上传...";
  isUploading: boolean = false; // 正在上传状态
  isFirstMount: boolean = true; // 控制防止重复回显
  headers = {
    Authorization: UserModule.accessToken,
  };
  uploadData = { wineryId: UserModule.wineryId };
  uploadUrl = `/api/v3/wineries/${UserModule.wineryId}/media/upload`;

  // Computed
  get imgList() {
    return this.value;
  }

  set imgList(value) {
    if (value.length < this.imgList.length) {
      // 判断是删除图片时同步el-upload数据
      this._syncElUpload(value);
    }
    // 同步v-model
    this.$emit("input", value);
  }

  get isMaxHidden() {
    return this.imgList.length >= this.limit;
  }

  get acceptType() {
    if (this.acceptMediaType.length > 0) {
      return this.acceptMediaType;
    }
    if (this.mediaType === "video") {
      return ".mp4,.3gp,m3u8";
    } else {
      return ".jpg,.png,svg";
    }
  }

  // Watch
  @Watch("value", { deep: true, immediate: true })
  onValueChange(value: IMediaModel[]) {
    if (this.isFirstMount && value.length > 0) {
      this.$nextTick(() => {
        this._syncElUpload();
      });
    }
  }

  // Methods

  mounted() {
    if (this.value.length > 0) this._syncElUpload();
  }

  // 同步el-upload数据
  _syncElUpload(val: IMediaModel[] | null = null) {
    const imgList = val || this.imgList;
    const uploadRef: any = this.$refs["uploadRef"];
    uploadRef.uploadFiles = imgList.map((item: IMediaModel, index: number) => {
      return {
        name: "pic" + index,
        url: item.path,
        status: "success",
        uid: createUniqueString(),
      };
    });
    this.isFirstMount = false;
  }

  beforeUpload(file: any) {
    this.uploadingText = "正在上传...";
    this.isFirstMount = false;
    if (this.useCompress) {
      // 图片压缩
    } else {
      if (validImgUpload(file, this.size)) {
        this.isUploading = true;
        return true;
      }
      return false;
    }
  }

  // 上传完单张图片
  onSuccessUpload(res: any) {
    if (res && res.data) {
      if (this.imgList.length < this.limit) {
        const { path, id } = res.data;
        const model: IMediaModel = {
          // path: '/public' + `/${path}`,
          path: `${path}`,
          id,
        };
        this.imgList.push(model);
      } else {
        this._syncElUpload();
        this.$message.error(res.resultMsg);
      }
      this.isUploading = false;
    }
  }

  // 移除单张图片
  onRemoveHandler(index: number) {
    this.$confirm(
      this.mediaType === "video" ? "确定删除该视频?" : "确定删除该图片?",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    )
      .then(() => {
        if (this.imgList.length === 1) {
          this.imgList.shift();
        } else {
          this.imgList.splice(index, 1);
        }
        this._syncElUpload();
      })
      .catch(() => {});
  }

  // 超限
  onExceed() {
    const upload: any = this.$refs["uploadRef"];
    upload.abort(); // 取消剩余接口请求
    this._syncElUpload();
    this.$message({
      type: "warning",
      message: `图片超限，最多可上传${this.limit}张图片`,
    });
  }

  onError(error: any) {
    this.$message.error("上传失败");
    this.uploadingText = "上传失败";
  }

  onDragStart(event: any) {
    event.target.classList.add("hideShadow");
  }

  onDragEnd(event: any) {
    event.target.classList.remove("hideShadow");
  }
}
